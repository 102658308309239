import React from 'react'

import './StackDatatable.scss'
import StackSection from './StackSection'
import {StackSectionConfig} from './types'

interface StackDatatableProps {
    sectionsConfig: StackSectionConfig[]
}

const StackDatatable: React.FC<StackDatatableProps> = ({sectionsConfig}) => {
    return <div className='stack-datatable'>
        {sectionsConfig.map((sectionConfig) => <StackSection sectionConfig={sectionConfig}/>)}
    </div>
}

export default StackDatatable