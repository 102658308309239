import React from 'react'
import {renderInterval} from '../utils'
import _ from 'lodash'

import './StackTaskCard.scss'
import {Link} from 'react-router-dom'
// @ts-ignore
import {Button} from '../../../../../../components/Button'

interface StackTaskCardProps {
  task: any
}

const StackTaskCard: React.FC<StackTaskCardProps> = ({task}) => {
    return <>
        <div>
            <Link to={`/tasks/${task.id}`}>
                <p className="card-title">{task.title || 'Fară titlu'}</p>
            </Link>
        </div>
        <div className="text-xs">
            {renderInterval(task)}
        </div>
        <div className="card-row">
            Responsabili: {_.join(task.owners.map((owner: any) => owner.full_name), ', ')}
        </div>
        <div className="card-row">
            Proiect: {task.project.name}
        </div>
        {
            task.clients_names?.length ? <div className="card-row">
                Clienți: {_.join(task.clients_names, ', ')}
            </div> : null
        }
        {
            task.duration_as_hours && <div className="card-row">
                Ore Lucrate: {task.duration_as_hours}
            </div>
        }

        <div className="card-footer">
            <Link to={`/tasks/${task.id}`}>
                <Button title='vezi detalii' variant='text' size='small'/>
            </Link>
        </div>
    </>
}

export default StackTaskCard