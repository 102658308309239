import React, {useState} from 'react'
import {Link} from 'react-router-dom'

import {isNull} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {toApiDateFormat, toApiTimeFormat} from '../../../../utils'
import {columns} from './constants'

import {AddTaskModal} from '../../../../components/AddTaskModal'
import {Button} from '../../../../components/Button'
import {Datatable} from '../../../../components/Datatable'

import './TasksList.scss'
import {useQueryParams} from 'use-query-params'
import {TableListFilter} from './filters'
import {useQueryResourceList} from '../../../../queries/rest'
import {keepPreviousData} from '@tanstack/react-query'
import {FilterForm} from '../../../../components/FilterForm'
import {StackDatatable} from '../../../../components/StackDatatable'
import {StackTaskCard} from './partials'
import FilterHeader from '../../../../components/Datatable/Filters/FilterHeader/FilterHeader'
import {Toggle} from '../../../../components/Toggle'
import {modalTypes} from '../../../../redux/modals'

export const TasksList = ({
    entityProfileID,
    selectedEntityID,
    modalIsOpen,
    updateTask
}) => {
    const [myTasks, setMyTasks] = useState(true)
    const [query, setQuery] = useQueryParams(TableListFilter.mapping)

    const today = new Date()
    const tomorrow = new Date().setDate(today.getDate() + 1)
    const nextMonday = new Date().setDate(today.getDate() + (8 - today.getDay()))
    const nextNextMonday = new Date().setDate(today.getDate() + (16 - today.getDay()))

    const {data: todayTasks} = useQueryResourceList(
        RESOURCES.tasks,
        {
            owner_id: myTasks ? entityProfileID : undefined,
            entity_id: selectedEntityID,
            start__lte: toApiDateFormat(tomorrow),
            page_size: 5,
            ...TableListFilter.getAPIFilters(query)
        },
        {
            placeholderData: keepPreviousData,
            enabled: !modalIsOpen
        }
    )

    const handleChangeCompletion = (task) => {
        let taskData = {completion: null}

        if (isNull(task.completion)) {
            const datetime = `${toApiDateFormat(new Date())}T${toApiTimeFormat(new Date())}`

            taskData = {completion: datetime}
        }

        updateTask(taskData, task.id)
    }

    return (
        <div className="page-info">
            <div className="page-info-extended">
                <div>
                    <div className="ml-5">
                        <Toggle label="Arată doar sarcinile mele" checked={myTasks} onChange={setMyTasks}/>
                    </div>
                    <FilterHeader
                        filterable
                        appliedFilters={TableListFilter.getUIFilters(query)}
                        filtersForm={() => <FilterForm filterClass={TableListFilter}/>}
                        handleRemoveFilter={(filter) => {
                            setQuery({
                                ...query,
                                [filter.key]: undefined
                            })
                        }}
                        searchable
                        searchPlaceholder="Caută sarcini"
                        searchDefaultValue={query.search}
                        onSearch={(event) => setQuery({
                            ...query,
                            search: event.target.value
                        })}
                    />
                </div>
                <Datatable
                    title="Astăzi"
                    data={todayTasks?.data?.results || []}
                    columns={[
                        ...columns,
                        {
                            Header: '',
                            accessor: 'id',
                            Cell: ({value: taskID, row: {original: task}}) => (
                                <div className="tasks-list-datatable-action-buttons-column">
                                    <Link to={`/tasks/${taskID}`}>
                                        <Button title="Vizualizare" variant="text" size="small"/>
                                    </Link>
                                    <Button
                                        title={isNull(task.completion) ? 'Finalizează' : 'Activează'}
                                        color={isNull(task.completion) ? 'secondary' : 'gray'}
                                        onClick={() => handleChangeCompletion(task)}
                                        variant="text"
                                        size="small"
                                    />
                                </div>
                            )
                        }
                    ]}
                    nextPage={todayTasks?.data?.pages.next}
                    previousPage={todayTasks?.data?.pages.previous}
                    currentPage={todayTasks?.data?.page}
                    totalPages={todayTasks?.data?.number_of_pages}
                    onChangePage={(page) => {
                        setQuery({
                            ...query,
                            page: page
                        })
                    }}
                />
                <StackDatatable sectionsConfig={[
                    ...([6, 0].includes(today.getDay()) ? [
                        {
                            sectionTitle: 'Restul săptămânii',
                            resource: RESOURCES.tasks,
                            resourceFilters: {
                                is_planned: true,
                                owner_id: myTasks ? entityProfileID : undefined,
                                start__gte: toApiDateFormat(tomorrow),
                                start__lte: toApiDateFormat(nextMonday)
                            },
                            useQueryOverrides: {
                                enabled: !modalIsOpen
                            },
                            render: (item) => <StackTaskCard task={item}/>,
                            filterConfig: TableListFilter
                        },
                        {
                            sectionTitle: 'In viitor',
                            resource: RESOURCES.tasks,
                            resourceFilters: {
                                is_planned: true,
                                owner_id: myTasks ? entityProfileID : undefined,
                                start__gte: toApiDateFormat(nextMonday)
                            },
                            useQueryOverrides: {
                                enabled: !modalIsOpen
                            },
                            render: (item) => <StackTaskCard task={item}/>,
                            filterConfig: TableListFilter
                        }
                    ] : [
                        {
                            sectionTitle: 'Săptămâna viitoare',
                            resource: RESOURCES.tasks,
                            resourceFilters: {
                                is_planned: true,
                                owner_id: myTasks ? entityProfileID : undefined,
                                start__gte: toApiDateFormat(nextMonday),
                                start__lte: toApiDateFormat(nextNextMonday)
                            },
                            useQueryOverrides: {
                                enabled: !modalIsOpen
                            },
                            render: (item) => <StackTaskCard task={item}/>,
                            filterConfig: TableListFilter
                        },
                        {
                            sectionTitle: 'In viitor',
                            resource: RESOURCES.tasks,
                            resourceFilters: {
                                is_planned: true,
                                owner_id: myTasks ? entityProfileID : undefined,
                                start__gte: toApiDateFormat(nextNextMonday)
                            },
                            useQueryOverrides: {
                                enabled: !modalIsOpen
                            },
                            render: (item) => <StackTaskCard task={item}/>,
                            filterConfig: TableListFilter
                        }
                    ]),
                    {
                        sectionTitle: 'Neplanificate',
                        resource: RESOURCES.tasks,
                        resourceFilters: {
                            is_planned: false,
                            owner_id: myTasks ? entityProfileID : undefined
                        },
                        useQueryOverrides: {
                            enabled: !modalIsOpen
                        },
                        render: (item) => <StackTaskCard task={item}/>,
                        filterConfig: TableListFilter
                    }
                ]}/>
            </div>

            <AddTaskModal/>
        </div>

    )
}

const mapStateToProps = (state) => {
    return {
        modalIsOpen: state.modals.type === modalTypes.ADD_TASK,
        entityProfileID: state.localConfigs.entityProfileID,
        selectedEntityID: state.localConfigs.selectedEntityID
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateTask: (taskData, taskID) => dispatch(RESOURCES.tasks.update(taskData, taskID))
})

export default connect(mapStateToProps, mapDispatchToProps)(TasksList)