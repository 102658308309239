import React from 'react'

interface StackSectionItemProps {
    item: any,
    render: ({item}: {item: any}) => React.Component
}

const StackSectionItem: React.FC<StackSectionItemProps> = ({item, render}) => {
    return <li className="stack-section-item">
        {render(item)}
    </li>
}

export default StackSectionItem