import React from 'react'
import {FilterConfigMapping} from './types'
import {Form, Formik} from 'formik'
import {getComponent} from './utils'
// @ts-ignore
import {Button} from '../../components/Button'
import _ from 'lodash'
import {connect} from 'react-redux'
// @ts-ignore
import {closeSlideover} from '../../redux/slideovers'
import {useQueryParams} from 'use-query-params'

interface FilterFormProps {
    filterClass: FilterConfigMapping,
    selectedEntityID: number,
    closeSlideover: () => void
}

const FilterForm: React.FC<FilterFormProps> = ({
    filterClass, selectedEntityID, closeSlideover
}) => {
    const [query, setQuery] = useQueryParams(filterClass.mapping)

    return <div className='slideover-form-container'>
        <Formik
            initialValues={query}
            onSubmit={(values) => {
                console.log(values)
                setQuery(values)
                closeSlideover()
            }}
        >
            {({setFieldValue, handleSubmit, values}) => (
                <>
                    <Form className='slideover-form'>
                        {_.entries(filterClass.config).filter(
                            ([filterName, {excludeFromForm}])=> !excludeFromForm
                        ).map(([filterName, fieldConfig])=> {
                            const Component = getComponent(fieldConfig.filterType)

                            return <Component
                                key={filterName}
                                value={values[filterName as keyof typeof values]}
                                filterName={filterName}
                                filterConfig={fieldConfig}
                                setFieldValue={setFieldValue}
                                selectedEntityID={selectedEntityID}
                            />
                        })}
                    </Form>
                    <div className="buttons-container">
                        <Button
                            title='Aplică'
                            onClick={handleSubmit}
                            type='submit'
                            fullWidth
                        />
                    </div>
                </>
            )}
        </Formik>
    </div>
}

const mapStateToProps = (state: any) => {
    return {
        selectedEntityID: state.localConfigs.selectedEntityID
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    closeSlideover: () => dispatch(closeSlideover())
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterForm)