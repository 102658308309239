import React from 'react'
// @ts-ignore
import {Input} from '../../../components/Input'
import {FilterFieldProps} from './types'

const BooleanFilter: React.FC<FilterFieldProps> = ({filterName, filterConfig, value, setFieldValue}) => {
    return <Input
        label={filterConfig.label}
        value={value}
        onChange={(e: any) => setFieldValue(filterName, e)}
        name={filterName}
        type="boolean"
        fullWidth
    />
}

export default BooleanFilter
