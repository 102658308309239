import React from 'react'
import {XMarkIcon} from '@heroicons/react/24/solid'

import './FilterTag.scss'

export default function FilterTag({filter, onRemove}) {
    return (
        <span className="tag-container">
            {filter.displayKey}: "{filter.displayValue}"
            {!filter?.defaultValue && <button type="button" className="tag-close-button" onClick={onRemove}>
                <span className="sr-only">Remove filter</span>
                <XMarkIcon className='close-icon'/>
            </button>}
        </span>
    )
}
