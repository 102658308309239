import React, {useState} from 'react'
import {Link} from 'react-router-dom'

import {isEmpty, isNil} from 'lodash'

import {uploadSignedFile} from 'avoapp-react-common/dist/redux/contracts'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {CANCELED_STATE} from '../../../../utils/constants'

import {Button} from '../../../../components/Button'
import {Datatable} from '../../../../components/Datatable'
import {Dropdown} from '../../../../components/Dropdown'
import {SignedFileDropzone} from '../../../../components/SignedFileDropzone'

import {columns} from './constants'

import './ContractsList.scss'
import {getTokenAndUploadFile} from '../../../../utils/files'
import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {toast} from 'react-toastify'
import {useQueryParams} from 'use-query-params'
import {tableListFilter} from './filters'
import {FilterForm} from '../../../../components/FilterForm'
import {useQueryResourceList} from '../../../../queries/rest'
import {keepPreviousData} from '@tanstack/react-query'
import {useDebouncedState} from '../../../../utils/hooks'

export const ContractsList = ({selectedEntityID}) => {
    const [searchFilter, setSearchFilter] = useDebouncedState('')
    const [page, setPage] = useState(1)

    const handleDropSignedFile = async (selectedFile, contractID) => {
        if(!isEmpty(selectedFile)) {
            const fileURL = await getTokenAndUploadFile(selectedFile, selectedEntityID)
            await performRequest(RESOURCES.contracts.update({signed_file: fileURL}, contractID))
            toast.success('Documentul semnat a fost încărcat cu succes.')
        }
    }

    const [query, setQuery] = useQueryParams(tableListFilter.mapping)

    const {data: contractsResponse, isFetching: contractsFetching} = useQueryResourceList(
        RESOURCES.contracts,
        {
            entity_id: selectedEntityID,
            page: page,
            search: searchFilter,
            ...tableListFilter.getAPIFilters(query)
        },
        {
            placeholderData: keepPreviousData,
            staleTime: 1000 * 5
        }
    )

    return (
        <Datatable
            data={contractsResponse?.data?.results || []}
            columns={[
                ...columns,
                {
                    Header: 'Acțiuni',
                    accessor: 'id',
                    Cell: ({value: contractID, row: {original: contract}}) => (
                        <div className='flex gap-2 items-center'>
                            <Link to={`/contracts/${contractID}/preview`}>
                                <Button title='Vizualizare' variant='text' size='small' />
                            </Link>
                            <Dropdown
                                title='Descarcă'
                                disabled={isNil(contract.file) && isNil(contract.signed_file)}
                                items={[
                                    {
                                        customContent: () => contract.file ? (
                                            <a
                                                href={contract.file}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className='avo-dropdown-menu-item'
                                            >
                                                Descarcă
                                            </a>
                                        ) : <React.Fragment />
                                    },
                                    {
                                        customContent: () => contract.signed_file ? (
                                            <a
                                                href={contract.signed_file}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className='avo-dropdown-menu-item'
                                            >
                                                Descarcă fișier semnat
                                            </a>
                                        ) : <React.Fragment />
                                    }
                                ]}
                                variant='text'
                                color='secondary'
                                size='small'
                            />
                            <Dropdown
                                title='Încarcă'
                                items={[
                                    {
                                        customContent: () => (
                                            <SignedFileDropzone
                                                onChange={(selectedFile) =>
                                                    handleDropSignedFile(selectedFile, contractID)
                                                }
                                            />
                                        )
                                    }
                                ]}
                                variant='text'
                                color='orange'
                                size='small'
                            />
                        </div>
                    )
                }
            ]}
            loading={contractsFetching}
            previousPage={contractsResponse?.data?.pages?.previous}
            currentPage={page}
            nextPage={contractsResponse?.data?.pages?.next}
            totalPages={contractsResponse?.data?.number_of_pages}
            onChangePage={(page) => {
                setPage(page)
            }}
            filterable
            filters={tableListFilter.getUIFilters(query)}
            filtersForm={() => <FilterForm filterClass={tableListFilter} />}
            handleRemoveFilter={(filter) => {
                setQuery({
                    ...query,
                    [filter.key]: undefined
                })
            }}
            searchable
            searchPlaceholder='Caută contracte'
            onSearch={(event) => {
                setSearchFilter(event.target.value)
            }}
            rowProps={row => ({
                className: row.original.state === CANCELED_STATE ? 'strikeout-row' : null
            })}
        />
    )
}

const mapStateToProps = (state) => ({
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    uploadSignedFile: (contractData, contractID) => dispatch(uploadSignedFile(contractData, contractID))
})

export default connect(mapStateToProps, mapDispatchToProps)(ContractsList)