
import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {PlusIcon} from '@heroicons/react/24/solid'

import {isEmpty, isNil} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {uploadSignedFile} from 'avoapp-react-common/dist/redux/contracts'
import {modalTypes, openModal} from '../../../../redux/modals'

import {columns} from './constants'
import {CANCELED_STATE, ISSUED_STATE} from '../../../../utils/constants'

import {Button} from '../../../../components/Button'
import {Dropdown} from '../../../../components/Dropdown'
import {Datatable} from '../../../../components/Datatable'
import {SignedFileDropzone} from '../../../../components/SignedFileDropzone'

import {
    // AssignContractModal,
    InvalidateContractModal,
    RemoveOrDeleteContractModal
} from './partials'

import './Contracts.scss'
import {getTokenAndUploadFile} from '../../../../utils/files'
import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {toast} from 'react-toastify'
import {useQueryParams} from 'use-query-params'
import {useQueryResourceList} from '../../../../queries/rest'
import {keepPreviousData} from '@tanstack/react-query'
import {useDebouncedState} from '../../../../utils/hooks'
import {FilterForm} from '../../../../components/FilterForm'
import {tableListFilter} from './filters'

export const Contracts = ({
    match: {params: {projectID}},
    openModal,
    updateContract,
    selectedEntityID
}) => {
    const [selectedContractID, setSelectedContractID] = useState('')
    const [searchFilter, setSearchFilter] = useDebouncedState('')
    const [page, setPage] = useState(1)

    const handleDropSignedFile = async (selectedFile, contractID) => {
        if(!isEmpty(selectedFile)) {
            const fileURL = await getTokenAndUploadFile(selectedFile, selectedEntityID)
            await performRequest(RESOURCES.contracts.update({signed_file: fileURL}, contractID))
            toast.success('Documentul semnat a fost încărcat cu succes.')
        }
    }

    const handleActivateContract = (contractID) => updateContract({state: ISSUED_STATE}, contractID)

    const [query, setQuery] = useQueryParams(tableListFilter.mapping)

    const {data: contractsResponse, isFetching: contractsFetching} = useQueryResourceList(
        RESOURCES.contracts,
        {
            entity_id: selectedEntityID,
            project_id: projectID,
            page: page,
            search: searchFilter,
            ...tableListFilter.getAPIFilters(query)
        },
        {
            placeholderData: keepPreviousData,
            staleTime: 1000 * 5
        }
    )

    return (
        <div className='page-project-show project-contracts-list'>
            {/*<AssignContractModal projectId={projectID} />*/}
            <RemoveOrDeleteContractModal contractID={selectedContractID} />
            <InvalidateContractModal contractID={selectedContractID} />
            <Datatable
                title='Contracte'
                data={contractsResponse?.data?.results || []}
                headerButton={() => (
                    <div className='contracts-datatable-buttons'>
                        {/* <Button
                            title='Assign contract'
                            onClick={() => openModal(modalTypes.ASSIGN_CONTRACT)}
                            icon={() => <LinkIcon />}
                            color='secondary'
                        /> */}
                        <Link to={`/contracts/add?initialProjectId=${projectID}`}>
                            <Button title='Adaugă contract' icon={() => <PlusIcon />} />
                        </Link>
                    </div>
                )}
                columns={[
                    ...columns,
                    {
                        Header: 'Acțiuni',
                        accessor: 'id',
                        Cell: ({value: contractID, row: {original: contract}}) => (
                            <div className='flex gap-2 items-center'>
                                <Link to={`/contracts/${contractID}/preview`}>
                                    <Button title='Vizualizare' variant='text' size='small' />
                                </Link>
                                <Dropdown
                                    title='Descarcă'
                                    disabled={isNil(contract.file) && isNil(contract.signed_file)}
                                    items={[
                                        {
                                            customContent: () => contract.file ? (
                                                <a
                                                    href={contract.file}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className='avo-dropdown-menu-item'
                                                >
                                                    Descarcă
                                                </a>
                                            ) : <React.Fragment />
                                        },
                                        {
                                            customContent: () => contract.signed_file ? (
                                                <a
                                                    href={contract.signed_file}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className='avo-dropdown-menu-item'
                                                >
                                                    Descarcă fișier semnat
                                                </a>
                                            ) : <React.Fragment />
                                        }
                                    ]}
                                    variant='text'
                                    color='secondary'
                                    size='small'
                                />
                                <Dropdown
                                    title='Încarcă'
                                    items={[
                                        {
                                            customContent: () => (
                                                <SignedFileDropzone
                                                    onChange={(selectedFile) => (
                                                        handleDropSignedFile(selectedFile, contractID)
                                                    )}
                                                />
                                            )
                                        }
                                    ]}
                                    variant='text'
                                    color='orange'
                                    size='small'
                                />
                                {!contract.deletion_prevented ? (
                                    <Button
                                        title='Șterge'
                                        onClick={() => {
                                            setSelectedContractID(contractID)
                                            openModal(modalTypes.REMOVE_OR_DELETE_CONTRACT)
                                        }}
                                        variant='text'
                                        size='small'
                                        color='red'
                                    />
                                ) : (
                                    <>
                                        {contract.state === CANCELED_STATE ? (
                                            <Button
                                                title='Activează'
                                                onClick={() => handleActivateContract(contractID)}
                                                variant='text'
                                                color='gray'
                                                size='small'
                                            />
                                        ) : (
                                            <Button
                                                title='Anulează'
                                                onClick={() => {
                                                    setSelectedContractID(contractID)
                                                    openModal(modalTypes.INVALIDATE_CONTRACT)
                                                }}
                                                variant='text'
                                                size='small'
                                                color='red'
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        ),
                        disableSortBy: true
                    }
                ]}
                loading={contractsFetching}
                previousPage={contractsResponse?.data?.pages?.previous}
                currentPage={page}
                nextPage={contractsResponse?.data?.pages?.next}
                totalPages={contractsResponse?.data?.number_of_pages}
                onChangePage={(page) => {
                    setPage(page)
                }}
                filterable
                filters={tableListFilter.getUIFilters(query)}
                filtersForm={() => <FilterForm filterClass={tableListFilter} />}
                handleRemoveFilter={(filter) => {
                    setQuery({
                        ...query,
                        [filter.key]: undefined
                    })
                }}
                searchable
                searchPlaceholder='Caută contracte'
                onSearch={(event) => {
                    setSearchFilter(event.target.value)
                }}
                rowProps={row => ({
                    className: row.original.state === CANCELED_STATE ? 'strikeout-row' : null
                })}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    openModal: (modalType) => dispatch(openModal(modalType)),
    uploadSignedFile: (contractData, contractID) => dispatch(uploadSignedFile(contractData, contractID)),
    updateContract: (contractData, contractID) => dispatch(RESOURCES.contracts.update(contractData, contractID))
})

export default connect(mapStateToProps, mapDispatchToProps)(Contracts)