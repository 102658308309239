import React from 'react'
// @ts-ignore
import {DatePicker} from '../../../components/DatePicker'
import {FilterFieldProps} from './types'

const DateFilter: React.FC<FilterFieldProps> = ({filterName, filterConfig, value, setFieldValue}) => {
    return <DatePicker
        label={filterConfig.label}
        value={value}
        onChange={(value: any) => setFieldValue(filterName, value ? new Date(value): undefined)}
        name={filterName}
        fullWidth
    />
}

export default DateFilter